import './referencesPreview.scss';
import React from 'react';
import Back from './back.jsx';

import ScrollToTop from "../helpers/scrollToTop";

const Preview = ({ image, name, job, company, text }) => {
  const generateImage = (item) => (
    <figure className='referencesPreview__imageWrapper'>
      <img src={item.src} alt={item.alt} className='referencesPreview__image' />
    </figure>
  );

  return (
    <div className='referencesPreview'>
      <header className='referencesPreview__header'>
        <div className='referencesPreview__headerContainer'>
          <Back />

          <h2 className='referencesPreview__hint'>{company}</h2>
        </div>
      </header>

      <section className='referencesPreview__section'>

        {generateImage(image)}

        <h1 className='referencesPreview__title'>{name}</h1>
        <h2 className='referencesPreview__hint'>{job}</h2>

        {text.map((text, i) => <p key={i} className='referencesPreview__text'>{text}</p>)}
      </section>
      <ScrollToTop />
    </div>
  );
}

export default Preview;