import React from 'react';
import { Link } from 'react-router-dom'

import LukaszSzostek from '../assets/images/lukasz-szostek.png';
import JarekPotiuk from '../assets/images/jarek-potiuk.png';
import MaciejOczko from '../assets/images/maciej-oczko.png';
import TomaszKobylarz from '../assets/images/tomasz-kobylarz.png';
import RadoslawJanusz from '../assets/images/radoslaw-janusz.png';
import MagdalenaZadara from '../assets/images/magdalena-zadara.png';
import MartinaTomis from '../assets/images/martina-tomis.png';
import MateuszGrzechocinski from '../assets/images/mateusz-grzechocinski.png';
import LeszekLeszczynski from '../assets/images/leszek-leszczynski.png';
import DavidVangeison from '../assets/images/david-vangeison.png';
import EricHeymann from '../assets/images/eric-heymann.png';
import MattiasForsberg from '../assets/images/mattias-forsberg.png';
import OskarTarringWeijden from '../assets/images/oskar-tarring-weijden.png';
import ThereseAhlstam from '../assets/images/therese-ahlstam.png';

export const references = [
  {
    image: {
      src: MattiasForsberg,
      alt: 'Mattias Forsberg'
    },
    name: 'Mattias Forsberg',
    job: 'CTO',
    company: 'Qliro AB',
    short: "I was impressed by Szymon's skilled leadership during this period and how he could traverse between strategy, technology as well as people.",
    text: [
      'I hired Szymon as a consultant to work with the Data-team at my current employer at the time. This was during a time when the team had serious issues with both staffing and technology.',
      'Szymon showed the ability to join the team and quickly get up to speed with the strategic direction we needed to go, the current state of solutions as well as sentiments of the staff and quite quickly built a plan on what to do short term as well as long term together with the team.',
      "I was impressed by Szymon's skilled leadership during this period and how he could traverse between strategy, technology as well as people. The work they did together created massive results in a short time leading to advantages thanks to data that previously were merely a dream.",
      "Worth mentioning is that Szymon's leadership remote is fantastic. I can warmly recommend working with Szymon, as he has a great sense of humor, highly skilled in technology and big-data solutions, and tells things as he sees them.",
      'You can always count on him telling you what he sees. This, I believe, is only one of the many qualities Szymon has that will be beneficial in any context he ends up in.'
    ],
    action: 'read more',
    href: '/references/Mattias-Forsberg'
  },
  {
    image: {
      src: EricHeymann,
      alt: 'Eric Heymann'
    },
    name: 'Eric Heymann',
    job: 'SVP Technology',
    company: 'smava GmbH',
    short: `His dedication and ability to deliver, as well as the way the whole team had been led through the project was exceptional, why I recommend him.`,
    text: [
      `Herewith I wholeheartedly recommend Szymon Nieradka for any future employer or client.`,
      `As SVP Technology at smava, Szymon was reporting into me and has been assigned to an important and urgent project for the company, which was modernizing the DWH and to buy us time by stabilizing the legacy DWH until then. This project was of very high complexity, due to the technical debt on the one hand, and the vast amount of different active and passive stakeholders and departments that were depending on correct and up-to-date reporting. The expectation was high and intense.`,
      `Szymon was able to form a cross-functional, cross-departmental project team that was able to assess and analyze the situation at hand as well as developing a step-by-step strategy to stabilize and scale the legacy solution first, and to decide on the future technology based on a POC that had been concluded and presented to all stakeholders, who agreed on the proposed provider.`,
      `His dedication and ability to deliver, as well as the way the whole team had been led through the project was exceptional, why I recommend him.`,
      `Thank you, Szymon, for your great work. It was a pleasure.`
    ],
    action: 'read more',
    href: '/references/Eric-Heymann'
  },
  {
    image: {
      src: OskarTarringWeijden,
      alt: 'Oskar Tarring Weijden'
    },
    name: 'Oskar Tarring Weijden',
    job: 'Head of Product and Customer Analytics',
    company: 'Qliro AB',
    short: 'A clever and tenacious project manager with both the practical know-how to get things done (and get his hands dirty when needed).',
    text: [
      'I have nothing but the best to day about Szymon. A clever and tenacious project manager with both the practical know-how to get things done (and get his hands dirty when needed) and the strategic mindset to create focus and direction. '
    ],
    action: 'read more',
    href: '/references/Oskar-Tarring-Weijden'
  },
  {
    image: {
      src: DavidVangeison,
      alt: 'David Vangeison'
    },
    name: 'David Vangeison',
    job: 'Chief Marketing and Growth Officer',
    company: 'smava GmbH',
    short: `Was great working with Szymon. One of the best project managers I have worked with in my career.`,
    text: [`Was great working with Szymon. One of the best project managers I have worked with in my career.`],
    href: '/references/David-Vangeison'
  },
  {
    image: {
      src: ThereseAhlstam,
      alt: 'Therese Ahlstam'
    },
    name: 'Therese Ahlstam',
    job: 'Senior Data Specialist',
    company: 'Top of Minds AB',
    short: 'He is a true joy to work with. Delivery-oriented and skilled at shifting through problems and getting to the root cause.',
    text: [
      'Szymon functioned as my team leader during a period of technically challenging platform migration and large colleague turnover. He is a true joy to work with; social and relaxed with team members, quick with sharing knowledge and equally quick with giving credit where due. He is delivery-oriented and skilled at sifting through problems and getting to the root cause.',
      'I have nothing but high praise for Szymon and would recommend him for most any role.'
    ],
    action: 'read more',
    href: '/references/Therese-Ahlstam'
  },
  {
    image: {
      src: JarekPotiuk,
      alt: 'Jarek Potiuk'
    },
    name: 'Jarek Potiuk',
    job: 'Principal Software Engineer',
    company: 'Polidea',
    short: `The description of Szymon "Project Manager who delivers" is absolutely true. He is one of those rare managers who actually deliver if only delivery is humanly possible, at the same time he will tell you immediately if the delivery is not possible. You want to work with Szymon. Period.`,
    text: [`You want to work with Szymon. Period.`,
      `I worked with Szymon in 2016-2018 at Polidea mostly (but not exclusively) on one of the most complex projects - extending Android Lollipop OS for Aevi Albert payment terminal. Actually Polidea hired Szymon with the thought he will manage the project (among other responsibilities) after we realised that the project is much more complex than we initially thought. It was more than 20 people working from distributed locations all over the Europe, where different sub-teams had different priorities and agendas. Szymon delivered that project and much more. He became Head of Client Projects at Polidea and pretty much transformed the whole company to follow great, lean practices when it comes to project management.`,
      `The description of Szymon "Project Manager who delivers" is absolutely true. He is one of those rare managers who actually deliver if only delivery is humanly possible, at the same time he will tell you immediately if delivery is not possible.`,
      `However limiting abilities of Szymon to "delivery only" (in its narrow definition) is quite an injustice to his skills and personality. I know many project managers who deliver and leave scorched earth behind. That's not who Szymon is. He is one of those very rare birds among project managers who not only delivers but also cares about people, their self-development and at the same time thinks about improving the organization he is in, not only about his project. Usually you find managers who have only one of those traits. It's a rare thing to find some PMs with just two of those. But someone like Szymon who thinks and delivers in all the three areas is almost unheard of.`,
      `So just to repeat what I wrote at the beginning: You want to work with Szymon. Period.`],
    action: 'read more',
    href: '/references/Jarek-Potiuk'
  },
  {
    image: {
      src: LukaszSzostek,
      alt: 'Łukasz Szóstek'
    },
    name: 'Łukasz Szóstek',
    job: 'Independent Consultant',
    company: '',
    short: `Without any hesitation, he is the best project manager I have ever met, and I did meet quite a lot of them in many companies on three continents. Whether you want him on your side or as a partner, you should be very happy.`,
	  text: [`I met Szymon in 2016 when one of my big clients (Play, the largest Polish telecom) required a mobile application crucial to their new spin-off project (Folx). My role at Folx was to deal with anything technical including the development of a mobile application. Szymon had introduced himself as the main contact to the development team but he turned out to be so much more.`,
      `Without any hesitation, he is the best project manager I have ever met, and I did meet quite a lot of them in many companies on three continents.`,
      `Whether you want him on your side or as a partner, you should be very happy. As a partner he delivers not only in his PM role but he has a lot of technical knowledge as well. As a “team manager” besides the skills listed above he has a unique ability to take care of both the team’s morale and the value delivered to the client.`],
    action: 'read more',
    href: '/references/Lukasz-Szostek'
  },
  {
    image: {
      src: MaciejOczko,
      alt: 'Maciej Oczko'
    },
    name: 'Maciej Oczko',
    job: 'Head of Engineering',
    company: 'Polidea',
    short: `I worked with Szymon at Polidea (2016-2018), he was Head of Client Services, and I was Head of Engineering. Szymon is the best project manager I’ve ever worked with. Give him a project, no matter how big or complex, and he will deliver it.`,
    text: [`I worked with Szymon at Polidea (2016-2018), he was Head of Client Services, and I was Head of Engineering.`,
      `Szymon is the best project manager I’ve ever worked with. Give him a project, no matter how big or complex, and he will deliver it.`,
      `There’s three aspects of that: practical intuition, analytical approach, and humble learning.`,
      `First, could be characterized by something, I call practical intuition. About the people in the team, about the product and about the client. His interpersonal skills and empathy allows him to have a gut feeling about upcoming issues long before they araise.`,
      `Second, he has deeply analytical mindset. It results in very thoughtful and smart reasoning, backed by technical background.`,
      `Third, Szymon is always eager to learn new things. Although, he has tons of experience, he’s willing to challenge status quo and perform mindful experiments.`,
      `Personally, working with Szymon has been often demanding and has put me out of my comfort zone a lot. And this was great.`],
    action: 'read more',
    href: '/references/Maciej-Oczko'
  },
  {
    image: {
      src: TomaszKobylarz,
      alt: 'Tomasz Kobylarz'
    },
    name: 'Tomasz Kobylarz',
    job: 'Product Owner',
    company: 'Wincor-Nixdorf',
    short: `He got up to speed really fast to lead an international team of 20+ members in several locations, helping to move things forward with his positive attitude, focus on the goals and creative approach to resolve problems and set up teams for success.`,
    text: [
      <span>I started cooperating with Szymon while my company was running one of the most complex and challenging projects in fin-tech industry (see <Link to="/products/Albert">Albert</Link>). He got up to speed really fast to lead an international team of 20+ members in several locations, helping to move things forward with his positive attitude, focus on goals, and a creative approach to resolve problems and set up teams for success.</span>,
      `After a few years of working together I can certainly say that he would be a great addition to any team - getting people to work together, always motivated, good observer and not to forget - just a great colleague to work with.`],
    action: 'read more',
    href: '/references/Tomasz-Kobylarz'
  },
  {
    image: {
      src: RadoslawJanusz,
      alt: 'Radosław Janusz'
    },
    name: 'Radosław Janusz',
    job: 'Mobile Banking Team Manager',
    company: 'PKO BP',
    short: `Szymon was responsible for the delivery of the second version of an award-winning mobile banking application IKO. He was always full of ideas, he understood the market very well and he knew what is possible within a fixed budget and schedule.`,
    text: ["Szymon was responsible for delivery of the second version of an award-winning mobile banking application IKO. He was managing the Bank's subcontractor team. I was a client of him.",
      'The team he managed was responsible for design and development of applications for four mobile platforms.',
      'He was always full of ideas, he understood the market very well, and he knew what is possible within a fixed budget and schedule.',
      'Having him on your side will for sure be an advantage on all levels.'],
    action: 'read more',
    href: '/references/Radoslaw-Janusz'
  },
  {
    image: {
      src: MagdalenaZadara,
      alt: 'Magdalena Zadara'
    },
    name: 'Magdalena Zadara',
    job: 'Senior Experience Designer',
    company: 'BCG Digital Ventures',
    short: `His getting-things-done and problem-solving attitude create a good working atmosphere, and he has a way of stakeholder management that makes it look effortless to the team.`,
    text: [`Szymon brings a mix of positive leadership, hands-on attitude and technical expertise to his teams. He is respectful of every individuals’ skills and knowledge; he is happy to collaborate on concepts and then lets specialists work on details without micro-managing. His getting-things-done and problem-solving attitude create a good working atmosphere and he has a way of stakeholder management that makes it look effortless to the team.`,
      'I worked with Szymon on several projects, including close collaboration where I reported directly to him and we worked together on a daily basis over several months.'],
    action: 'read more',
    href: '/references/Magdalena-Zadara'
  },
  {
    image: {
      src: MartinaTomis,
      alt: 'Martina Tomis'
    },
    name: 'Martina Tomis',
    job: 'Business Analyst',
    company: 'Aevi UK',
    short: `Szymon honestly and truly cares. By combining an open mind, understanding, and creative and focused thinking, he always gets to the most efficient solution which keeps everyone happy. I consider Szymon not to be merely our project manager, but also my friend and mentor.`,
    text: ['Szymon honestly and truly cares. By combining an open mind, understanding, and creative and focused thinking, he always gets to the most efficient solution which keeps everyone happy.',
      'We spent almost 2 years working together on a challenging Android-based product with critical security requirements. Szymon was our project manager and I was a business analyst.',
      'I could always rely on Szymon’s support. He creates a friendly atmosphere which allows everyone to get to the core of the problem and resolve it with ease and smile.',
      'I’m grateful for everything that I was able to learn from Szymon. The most impressive artistry that Szymon masters is a truly agile mindset and the magic of keeping things simple, yet powerful.',
      'I consider Szymon not to be merely our project manager, but also my friend and mentor.',
      'If you need to get things done and people happy, Szymon is exactly the person to go to.'],
    action: 'read more',
    href: '/references/Martina-Tomis'
  },
  {
    image: {
      src: MateuszGrzechocinski,
      alt: 'Mateusz Grzechociński'
    },
    name: 'Mateusz Grzechociński',
    job: 'Project Lead Engineer',
    company: 'Polidea',
    short: `I can truly admit that in my 8 years of professional career, I was lucky enough to be managed by people who were an extraordinary mixture of technical and soft skills and who believed in other people. Szymon is definitely one of those guys and the one who influenced me the most.`,
    text: ['I can truly admit that in my 8 years of professional career, I was lucky enough to be managed by people who were an extraordinary mixture of technical and soft skills and who believed in other people. Szymon is definitely one of those guys and the one who influenced me the most.',
      'We collaborated for over a year and a half in the IKO project (the biggest Central European mobile payment solution) where Szymon was the main leader, responsible for the whole solution and the communication bridge between the customer and development teams.',
      'First of all, Szymon is simply a guy you can count on. Extremely communicative, but no over-communicative. With solid technical background, he is focused on customer needs and always tries to find the best solution. He believes in team spirit and everyone’s self-development which is always followed by great product quality. With perfect timing, never forgets about anything, especially blocker issues. He understands terms like technical debt, testing, refactoring. The last with some slight problems, though ;)',
      'Last but not least, I really admire his attitude to other people. Personally, he gave me a lot of freedom in terms of making decisions while I’ve been leading the mobile development team. That gave me a lot of self-confidence and willingness to always move forward. I always knew he is willing to help, give advice, motivate and to criticize as well. I learnt a lot from him and I really appreciate it.',
      'Having project led by Szymon means leaving it in good hands.'],
    action: 'read more',
    href: '/references/Mateusz-Grzechocinski'
  },
  {
    image: {
      src: LeszekLeszczynski,
      alt: 'Leszek Leszczyński'
    },
    name: 'Leszek Leszczyński',
    job: 'Tech Leader',
    company: 'Hewlett-Packard',
    short: `A unique combination of technical background, business awareness, and great communication skills make Szymon one of the best leaders I have ever worked with. He somehow manages to balance timely delivery with actual care for his team, painlessly removing hurdles and motivating people with his positive thinking.`,
    text: ['A unique combination of technical background, business awareness, and great communication skills make Szymon one of the best leaders I have ever worked with. He somehow manages to balance timely delivery with actual care for his team, painlessly removing hurdles and motivating people with his positive thinking. His brash attitude helps him solve seemingly troublesome issues with little effort. A remarkable example of a person you just want to work with.'],
    action: 'read more',
    href: '/references/Leszek-Leszczynski'
  }
];
