import './heading.scss';
import classNames from 'classnames';
import React from 'react';

export default ({ text, color, type }) => {
  const headingClass = classNames('heading', `heading--${color}`, `heading--${type}`);

  return (
    <h2 className={headingClass}>
      {text}
    </h2>
  );
};

