import AzLogo from '../assets/images/az-logo.svg';
import PolideaLogo from '../assets/images/polidea-logo.svg';
import BlStreamLogo from '../assets/images/blstream-logo.svg';
import StoczniaLogo from '../assets/images/stocznia-logo.svg';

export const jobs = [
  {
    company: 'Polidea',
    job: 'Head of Client Projects',
    logo: PolideaLogo,
    content: [
      {
        title: 'Head of Client Projects',
        company: 'Polidea',
        logo: PolideaLogo,
        time: '2016 – 2018',
        description: [
          {
            type: 'text',
            value: `As Head of Client Projects at Polidea, my main goal was to introduce client-oriented project management to the company. Main achievements:`
          },
          {
            type: 'list',
            value: [
              `a new project management culture was introduced, heavily oriented on goal, delivery, and short learning cycle;`,
              `one-on-one feedback was successfully implemented in the company;`,
              `teams perform perfect sprint reviews and retrospectives;`,
              `hiring and training four new project managers (including one internally hired);`,
              `acquiring and ability to handover three €1M projects;`,
              `leadership of the most significant three projects in Polidea's history followed by successful delivery to clients.`
            ]
          }
        ]
      }
    ]
  },
  {
    company: 'AZ.pl',
    job: 'Managing Director',
    logo: AzLogo,
    content: [
      {
        title: 'Managing Director',
        company: 'AZ.pl',
        logo: AzLogo,
        time: '2010 – 2011',
        description: [
          {
            type: 'text',
            value: `Back in 2009 AZ.pl was the third biggest domain registrar in Poland with 11% of market share.`
          },
          {
            type: 'text',
            value: `Results of my work as a managing director:`
          },
          {
            type: 'list',
            value: [
              `company's market share increased by 1.4% in 12 months;`,
              'corporate-identity was refreshed and a new website was introduced;',
              'company successfully entered secondary domain market and drop-catching services;',
              'company moved to a new datacenter and completely redesigned the hosting infrastructure;',
              'customer support was optimized, help-desk and FI-CO departments were redesigned, non-core services were outsourced.'
            ]
          }
        ]
      }
    ]
  },
  {
    company: 'BLStream',
    job: 'Director, Sales and Business Development',
    logo: BlStreamLogo,
    content: [
      {
        title: 'Director, Sales and Business Development',
        company: 'BLStream',
        logo: BlStreamLogo,
        time: '2006 – 2009',
        description: [
          {
            type: 'text',
            value: 'During my 3 year long career at BLStream I was responsible for project and product management, account management and sales.'
          },
          {
            type: 'text',
            value: `Example results of my work at BLStream:`
          },
          {
            type: 'list',
            value: [
              `establishing long-term cooperation with the biggest insurance company in the region;`,
              `several projects for telco and insurance companies were delivered (up to 12 team-members);`,
              `performed series of lectures on Java Enterprise Edition at Szczecin's University (approximately 150 listeners);`,
              'led HIJA project (High Integrity Java), the main goal was to implement a new JVM for networked real-time embedded systems;',
              `led part of Mobilife project as a team leader, the main goal was to present future abilities of mobile devices with 3G (it was 2006).`
            ]
          }
        ]
      }
    ]
  },
  {
    company: `Szczecin's New Shipyard`,
    job: 'Chief Information and Telecommunication Officer',
    logo: StoczniaLogo,
    content: [
      {
        title: 'Chief Information and Telecommunication Officer',
        company: `Szczecin's New Shipyard`,
        logo: StoczniaLogo,
        time: '2004 – 2006',
        description: [
          {
            type: 'text',
            value: `During my 3.5 years at Szczecin's New Shipyard I formed a new IT department from scratch. New shipyard was a successor of the previous company supported by over one hundred IT employees. My role was to provide services for almost the same-sized company (10K employees) while having a five times smaller team.`
          },
          {
            type: 'text',
            value: `Example results of my work as CIO:`
          },
          {
            type: 'list',
            value: [
              `an IT department of 20 employees built from scratch;`,
              `most of the non-core services were outsourced;`,
              `shipyard’s old trucking-radios system was replaced by mobile phones (550 users);`,
              `old telephone exchanges were replaced by a modern CBX, the purchase of the equipment was lowered by 70%;`,
              `Microsoft GreatPlains was implemented from 150 users in ﬁnance, accounting, warehousing and controlling.`
            ]
          }
        ]
      }
    ]
  }
];
