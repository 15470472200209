import React from 'react';

import Preview from '../components/productPreview';
import { useLoaderData } from "react-router-dom";

const Details = () => {
  const content = useLoaderData()

  return (
    <Preview {...content} />
  );
};

export default Details;