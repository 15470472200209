import './description.scss';
import Heading from './heading.jsx';
import { competences } from '../translation';
import React from 'react';
import experienceIcon from '../assets/images/experience-icon.svg';

competences.list
competences.text
 
const Description = () =>  (
  <div className='description'>
    <div className='description__content'>
      <img src={experienceIcon} className='description__icon' alt='light bulb icon' />
      <Heading text={competences.heading} />
      {competences.text.map((item, i) => <p key={i} className='description__text'>{item}</p>)}
    </div>

    <ul className='description__list'>
      {competences.list.map((item, i) => <li key={i} className='description__item'>{item}</li>)}
    </ul>
  </div>
);

export default Description;