export const education = {
  heading: 'Education',
  list: [
    {
      school: 'Technical University of Berlin',
      city: 'Berlin',
      date: '2023',
      description: 'Innovation & Entrepreneurship'
    },
    {
      school: 'WSB University',
      city: 'Poznan, PL',
      date: '2012 – 2013',
      description: 'Postgraduate one-year-long coaching course, graduated with an A grade.'
    },
    {
      school: 'WSB University and Aalto University School of Economics',
      city: 'Poznan, PL and Aalto, FI',
      date: '2010 – 2012',
      description: 'Aalto Executive MBA, accreditations: AMBAs, AACSB, EQUIS.'
    },
    {
      school: 'Polish-Japanese Institute of Information Technology',
      city: 'Warsaw, PL',
      date: '2006 – 2009',
      description: 'Master of Science in Computer Science graduated with an overall A grade.'
    },
    {
      school: 'Technical University of Szczecin',
      city: 'Szczecin, PL',
      date: '2002 – 2004',
      description: 'Bachelor of Engineering in Computer Science graduated with an overall A grade.'
    }
  ]
};