import './hero.scss';
import { hero } from '../translation';
import React from 'react';

export default () => {
  const { title, text, image, shape, cta} = hero;

  return (
    <header className='hero'>
      <div className='hero__container'>
        <div className='hero__content'>
          <h1 className='hero__title'>{title}</h1>

          <div className='hero__description'>
            {text.map((item, i) => <p key={i} className='hero__text'>{item}</p>)}
          </div>

          <a className='hero__cta' href={cta.href} target="_blank" rel="noopener noreferrer" title={cta.title}>{cta.text}</a>
        </div>

          <img src={image.src} alt={image.alt} className='hero__image' />
          <img src={shape.src} alt={image.alt} className='hero__bgShape' />
        </div>

        <div className='hero__shape' />
    </header>
  );
};
