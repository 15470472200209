import React, { useState } from 'react';
import './tabs.scss';
import classNames from 'classnames';
import { jobs } from '../translation';

const Tabs = (props) => {
  const [state, setState] = useState({
    activeIndex: 0
  })

  function setActiveTab(index) {
    setState({ activeIndex: index });
  }

  function generateClassNames(index) {
    return classNames('tabs__tab', { ['tabs__tab--active']: state.activeIndex === index });
  }

  const generateItem = (item, index) => (
    <li className={generateClassNames(index)} onClick={() => setActiveTab(index)} key={index}>
      <div className='tabs__previewWrapper'>
        <img src={item.logo} alt={`${item.title} logo`} className='tabs__logo' />
      </div>

      <div>
        <p className='tabs__subtitle'>{item.job}</p>
        <span className='tabs__heading'>{item.company}</span>
      </div>
    </li>
  );

  const generateDescription = (content, index) => (
    <article key={index} className='tabs__body'>
      <header className='tabs__header'>
        <span className='tabs__time'>{content.time}</span>
        <h4 className='tabs__title'>{content.title}</h4>
        <h3 className='tabs__company'>{content.company}</h3>
      </header>

      <section className='tabs__article'>
        {content.description.map((item, i) => {
          if (item.type === 'text') {
            return <p key={i}>{item.value}</p>;
          }
          else if (item.type === 'list') {
            return <ul key={i}>{item.value.map((value, i) => <li key={i}>{value}</li>)}</ul>;
          }
          else return null
        })}
      </section>
    </article>
  )

  return (
    <section className='tabs'>
      <div className='tabs__navbar'>
        <ul className='tabs__list'>
          {jobs.map((item, index) => generateItem(item, index))}
        </ul>
      </div>

      <div className='tabs__content'>
        {jobs[state.activeIndex].content.map((content, index) => generateDescription(content, index))}
      </div>
    </section>
  );
}

export default Tabs;