import './section.scss';
import classNames from 'classnames';
import React from 'react';

export default ({ className, children, modifier, type, pushed, id }) => {
  const sectionClass = classNames('section', className, `section--${modifier}`, `section--${type}`);

  return (
    <section className={sectionClass} id={id}>
      <div className='section__shape' />

      <div className='section__container'>
        {children}
      </div>
    </section>
  );
};
