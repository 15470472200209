import './logos.scss';
import { logos } from '../translation/index';
import React from 'react';

export default ({ id }) => (
  <div className='logos' id={id}>
    <h3 className='logos__heading'>Some brands I worked with</h3>

    <div className='logos__body'>
      {logos.map((logo, i) => <img key={i} src={logo.src} alt={logo.name} className='logos__image' />)}
    </div>
  </div>
);
