import React, { Component } from 'react';
import './carousel.scss';
import classNames from 'classnames';
import debounce from '../helpers/debounce';

export default class Carousel extends Component {
  state = {
    activeIndex: 0,
    translate: 0,
    slideWidth: 50,
    touchstartx: undefined,
    touchmovex: undefined,
    movex: undefined
  }

  SWIPE_SIZE = 50
  SCREEN_MD = 900

  slideTo(index) {
    this.setState({ activeIndex: index, translate: `${-this.state.slideWidth * index}` });
  }

  slide(reverse = false) {
    let index = this.state.activeIndex;
    index += reverse ? 1 : -1;

    if (index < 0) { index = 0; }
    this.slideTo(index);
  }

  setActiveDotClass(index) {
    return index === this.state.activeIndex ? 'carousel__dot--active' : null;
  }

  onTouchStart = (e) => {
    this.setState({ touchstartx: e.touches[0].pageX });
  }

  onTouchMove = (e) => {
    let touchmovex = e.touches[0].pageX;
    let movex = this.state.touchstartx - touchmovex;

    if (Math.abs(movex) > this.SWIPE_SIZE) {
      this.setState({
        touchmovex: e.touches[0].pageX,
        translate: -((this.state.activeIndex * this.state.slideWidth) + (this.state.touchstartx - touchmovex)),
        movex
      });
    }
  }

  onTouchEnd = (e) => {
    const { activeIndex, translate, movex, slideWidth } = this.state;
    const currentTranslate = activeIndex * -slideWidth;
    let index = activeIndex;

    if (Math.abs(movex) > this.SWIPE_SIZE) {
      if (translate < currentTranslate && activeIndex < (this.props.children.length - 1)) {
        index++;
      }
      else if (translate > currentTranslate && activeIndex > 0) {
        index--;
      }

      this.slideTo(index);
    }
  }

  modifyChildren(child, index) {
    return <li key={index} className='carousel__item'>{child}</li>;
  }

  setSlideWidth() {
    if (window.innerWidth < this.SCREEN_MD) {
      this.setState({ slideWidth: 100, translate: `${-this.state.activeIndex * 100}`});
    } else {
      this.setState({ slideWidth: 50, translate: `${-this.state.activeIndex * 50}` });
    }
  }

  handleResize = debounce(() => { this.setSlideWidth() }, 400);

  componentDidMount() {
    this.setSlideWidth();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const listTransition = {
      transform: `translate3d(${this.state.translate}%, 0px, 0px)`,
      transitionDuration: `400ms`
    };

    return (
      <div className='carousel'>
        <div className={'carousel__content'}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
        >
          <ul className='carousel__list' style={listTransition}>
            {this.props.children.map((child, i) => this.modifyChildren(child, i))}
          </ul>
        </div>

        <ul className='carousel__dots'>
          {this.props.children.map((item, index) => <li key={index} className={classNames('carousel__dot', this.setActiveDotClass(index))} onClick={() => this.slideTo(index)} />)}
        </ul>

        <button
          type='button'
          title='previous reference'
          className={classNames('carousel__button', 'carousel__button--left')}
          onClick={() => this.slide(false)}
          disabled={this.state.activeIndex === 0}
        />

        <button
          type='button'
          title='next reference'
          className={classNames('carousel__button', 'carousel__button--right')}
          onClick={() => this.slide(true)}
          disabled={this.state.activeIndex >= this.props.children.length - 1}
        />
      </div>
    );
  }
}
