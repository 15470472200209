import WincorLogo from '../assets/images/wincor-logo.svg';
import HPLogo from '../assets/images/hp-logo.svg';
import DolbyLogo from '../assets/images/dolby-logo.svg';
import PKOLogo from '../assets/images/pko-logo.svg';
import GoogleLogo from '../assets/images/google-logo.svg';
import PZULogo from '../assets/images/pzu-logo.svg';
import PlayLogo from '../assets/images/play-logo.svg';

import HeroImage from '../assets/images/szymon-nieradka-hero.png';
import HeroShape from '../assets/images/shape_hero.png';
import LinkedInIcon from '../assets/icons/linkedIn-icon.svg';
import MediumIcon from '../assets/icons/medium-icon.svg';

const header = {
  logo: 'Szymon Nieradka',
  nav: [
    'About',
    'Products & Jobs',
    'References',
    'Contact'
  ]
};

const hero = {
  title: 'I am a project manager and I deliver',
  subtitle: 'I am a project manager and I deliver',
  text: [
    'Skilled at forming and leading highly driven teams that deliver projects with maximum efficiency. Proven interdisciplinary skills perfected while completing projects that spanned teams in several countries. Highly effective in connecting business decision-makers with teams of engineers and designers. Successfully empowering existing teams to grow and deliver.',
    'Significantly contributed to the Polish e-payment revolution by creating an award-winning app for mobile payment services for the largest bank in Central Europe.',
    'Do you have a project to be delivered? Or is your data team bleeding out over a legacy warehouse? Schedule a call!'
  ],
  cta: {
    text: "Let's talk",
    href: 'https://calendly.com/szn/30min',
    title: 'Schedule our first 30 minutes call via Google Meet',
  },
  image: {
    src: HeroImage,
    alt: 'Szymon Nieradka photo'
  },
  shape: {
    src: HeroShape,
    alt: 'Background shape'
  }
};

const competences = {
  heading: 'Experience',
  text: [
    'I am experienced in managing complex projects that require multi-functional teams to cooperate. I delivered mobile applications, websites, hardware products, desktop and backend apps, big-data services, and one operating system as well.',
    'I have experience as a managing director and chief information officer. I worked with startups, spin-offs, and corporations.',
    'In recent years, I have specialized in migrating legacy on-premise data warehouses to the cloud.'
  ],
  list: [
    '10+ years of project management (mobile, web, embedded, IoT, enterprise)',
    '5 years as an agile coach/head of project management',
    '3 years of C-level management experience',
    '3 years of experience in anything to Snowflake migration',
    'Delivered several €1m-€2m projects (counting billable hours alone)',
    'Leading teams of 30+ people, both in-house and distributed internationally',
    'Failed startup experience'
  ]
};

const logos = [
  {
    src: WincorLogo,
    name: 'Wincor Nixdorf'
  },
  {
    src: HPLogo,
    name: 'HP'
  },
  {
    src: DolbyLogo,
    name: 'Dolby'
  },
  {
    src: PKOLogo,
    name: 'PKO'
  },
  {
    src: GoogleLogo,
    name: 'Google'
  },
  {
    src: PZULogo,
    name: 'PZU'
  },
  {
    src: PlayLogo,
    name: 'Play'
  }
];

const footer = {
  location: {
    header: 'Location',
    text: 'Berlin / Remote',
  },
  contact: {
    header: 'Contact',
    phone: {
      text: '+48 693 373 068',
      href: 'tel:+48693373068',
    },
    email: {
      text: 'nier@dka.email',
      href: 'mailto:nier@dka.email?subject=Hello!',
    }
  },
  links: {
    header: 'LinkedIn & Medium profiles',
    linkedin: {
      icon: LinkedInIcon,
      href: 'https://www.linkedin.com/in/snieradka/'
    },
    medium: {
      icon: MediumIcon,
      href: 'https://medium.com/@szymon.nieradka'
    }
  },
  logo: 'szymon{nieradka}',
  copyright: '© 2018–2023',
  shape: HeroShape
};

const education = require('./education').education;
const products = require('./products').products;
const references = require('./references').references;
const jobs = require('./jobs').jobs;

export {
  header,
  hero,
  competences,
  education,
  products,
  references,
  jobs,
  logos,
  footer
};
