import React from 'react';

export const LocationIcon = () => (
  <svg width="6px" height="9px" viewBox="0 0 6 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none">
          <g transform="translate(-4.000000, -72.000000)" fillRule="nonzero">
              <g transform="translate(4.000000, 72.000000)">
                  <path d="M3,9 C5,6.01466476 6,3.98570592 6,2.91312347 C6,1.30424981 4.65685425,0 3,0 C1.34314575,0 0,1.30424981 0,2.91312347 C0,3.98570592 1,6.01466476 3,9 Z" fill="currentColor"></path>
                  <circle fill="#FFFFFF" cx="3" cy="3" r="1"></circle>
              </g>
          </g>
      </g>
  </svg>
);

export const OfficeIcon = () => (
<svg width="13px" height="15px" viewBox="0 0 13 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
        <g transform="translate(-307.000000, -5044.000000)">
            <g transform="translate(307.000000, 5044.000000)" fill="currentColor" fillRule="nonzero">
                <path d="M2,9.5 L2,9 L3,10 L2,10 L3,9 L3,10 L2,9 L2,9.5 Z M2,9 L3,9 L3,10 L2,10 L2,9 Z" ></path>
                <path d="M4,9.5 L4,9 L5,10 L4,10 L5,9 L5,10 L4,9 L4,9.5 Z M4,9 L5,9 L5,10 L4,10 L4,9 Z" ></path>
                <path d="M3.5,13 L3.5,14 L5.5,14 L5.5,13 L3.5,13 Z M2.5,12 L6.5,12 L6.5,15 L2.5,15 L2.5,12 Z" ></path>
                <path d="M1,14 L8,14 L8,1.89504279 L1,1.11726502 L1,14 Z M-3.63797881e-12,1.0061539 L2.7000624e-11,0.895042793 L0.889568474,0.0988409415 L1,0.111111111 L1,1.11726502 L-3.63797881e-12,1.0061539 Z M-7.95807864e-13,0 L9,1 L9,15 L-7.95807864e-13,15 L-7.95807864e-13,0 Z" ></path>
                <path d="M9,14 L12,14 L12,8.43233947 L9,8.11233947 L9,14 Z M8,8.0056728 L8,7.89900613 L8.89393502,7.09535307 L9,7.10666667 L9,8.11233947 L8,8.0056728 Z M8,7 L13,7.53333333 L13,15 L8,15 L8,7 Z" ></path>
                <path d="M10,9.5 L10,9 L11,10 L10,10 L11,9 L11,10 L10,9 L10,9.5 Z M10,9 L11,9 L11,10 L10,10 L10,9 Z" ></path>
                <path d="M10,11.5 L10,11 L11,12 L10,12 L11,11 L11,12 L10,11 L10,11.5 Z M10,11 L11,11 L11,12 L10,12 L10,11 Z" ></path>
                <path d="M10,13.5 L10,13 L11,14 L10,14 L11,13 L11,14 L10,13 L10,13.5 Z M10,13 L11,13 L11,14 L10,14 L10,13 Z" ></path>
                <path d="M6,9.5 L6,9 L7,10 L6,10 L7,9 L7,10 L6,9 Z M6,9.5 L6,10 L6,9.5 Z M6,9 L7,9 L7,10 L6,10 L6,9 Z" ></path>
                <path d="M2,6.5 L2,6 L3,7 L2,7 L3,6 L3,7 L2,6 L2,6.5 Z M2,6 L3,6 L3,7 L2,7 L2,6 Z" ></path>
                <path d="M4,6.5 L4,6 L5,7 L4,7 L5,6 L5,7 L4,6 L4,6.5 Z M4,6 L5,6 L5,7 L4,7 L4,6 Z" ></path>
                <path d="M6,6.5 L6,6 L7,7 L6,7 L7,6 L7,7 L6,6 L6,6.5 Z M6,6.5 L6,7 L6,6.5 Z M6,6 L7,6 L7,7 L6,7 L6,6 Z" ></path>
                <path d="M2,3.5 L2,3 L3,4 L2,4 L3,3 L3,4 L2,3 L2,3.5 Z M2,3 L3,3 L3,4 L2,4 L2,3 Z" ></path>
                <path d="M4,3.5 L4,3 L5,4 L4,4 L5,3 L5,4 L4,3 L4,3.5 Z M4,3 L5,3 L5,4 L4,4 L4,3 Z" ></path>
                <path d="M6,3.5 L6,3 L7,4 L6,4 L7,3 L7,4 L6,3 L6,3.5 Z M6,3.5 L6,4 L6,3.5 Z M6,3 L7,3 L7,4 L6,4 L6,3 Z" ></path>
            </g>
        </g>
    </g>
</svg>
);