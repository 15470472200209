import './flex-list.scss';
import Card from './card.jsx';
import { products } from '../translation';
import React from 'react';

const productCards = products.filter(product => product.type === 'preview');

export default () => (
  <section className='flexList'>
    <h4 className='flexList__heading'>See more projects</h4>

    <div className='flexList__content'>
      <div className='flexList__container'>

        <ul className='flexList__list'>
          {productCards.map((product, i) => (
            <li className='flexList__item' key={i}>
              <Card content={product} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
);
