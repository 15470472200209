import React from 'react';

import Folx from '../assets/images/folx.png';
import FolxDemo from '../assets/images/folx-demo.jpg';
import FolxRetro from '../assets/images/folx-retro.jpg';
import FolxStandup from '../assets/images/folx-standup.jpg';
import FolxTests from '../assets/images/folx-tests-2.jpg';
import FolxGif from '../assets/images/folx-anim.gif';

import Albert from '../assets/images/albert-project.png';
import AlbertDemo from '../assets/images/albert-demo.jpg';
import AlbertWorkshops from '../assets/images/albert-workshop.jpg';
import AlbertSlack from '../assets/images/albert-slack.png';
import AlbertMeetup from '../assets/images/albert-meetup.jpg';
import AlbertTalk from '../assets/images/albert-talk.png';

import IkoProject from '../assets/images/iko-project.png';
import IkoBoard from '../assets/images/iko-board.jpg';
import IkoJunior from '../assets/images/iko-junior.jpg';
import IkoOrganic from '../assets/images/iko-organic.jpg';
import IkoWork from '../assets/images/iko-work.jpg';

import Stojaki from '../assets/images/stojaki.jpg';
import StojakiBirthday from '../assets/images/stojaki-birthday.jpg';
import StojakiInside from '../assets/images/stojaki-inside.jpg';

import Microbus from '../assets/images/microbus.png';
import MicrobusNokia from '../assets/images/microbus-nokia.png';
import MicrobusSymbian from '../assets/images/microbus-symbian.png';

import Blik from '../assets/images/blik.png';
import BlikDemo from '../assets/images/blik-demo.jpg'

import Evently from '../assets/images/evently.png';
import EventlyPage from '../assets/images/Evently-4.png';

import EngryFile from '../assets/images/angryfile.png';
import EngryFileLogo from '../assets/images/angryfile-logo.jpg';
import EngryFileFanpage from '../assets/images/angryfile-fanpage.png';

// import smavaProduct from '../assets/images/smava-goal.jpg';
import smavaLogo from '../assets/images/smava-logo.png';
import smavaLogoCircle from '../assets/images/smava-logo-circle.png';
import smavaBoard from '../assets/images/smava-board.jpg';
import smavaContract from '../assets/images/smava-contract.jpg';
import smavaGoal from '../assets/images/smava-goal.jpg';
import smavaRoom from '../assets/images/smava-room.jpg';
import smavaTT1 from '../assets/images/smava-tigerteam1.jpg';
import smavaTT2 from '../assets/images/smava-tigerteam2.jpg';

import qliroLogo from '../assets/images/qliro-logo.png';
import qliroLogoCircle from '../assets/images/qliro-logo-circle.png';
import qliroFinish from '../assets/images/qliroFinish.jpg';
import qliroFun2 from '../assets/images/qliroFun2.png';
import qliroStart1 from '../assets/images/qliroStart1.jpg';
import qliroStart2 from '../assets/images/qliroStart2.jpg';

import snowflakeProject from '../assets/images/snowflake-project.png';

export const products = [
  {
    type: 'description',
    image: {
      src: snowflakeProject,
      alt: 'Helping Data Teams to thrive'
    },
    years: '2020 – 2023',
    title: 'Snowflake – high-performing Data Teams',
    text: 'Between 2020 and 2023, I helped German and Swedish fintechs transform their outdated, on-premise data warehouses to Snowflake.',
    content: [
      {
        type: 'p',
        value: 'I helped Qliro transform both, the Data Team and data warehousing infrastructure. Abandoned Spark pipelines were successfully migrated to GIT-driven DBT pipelines running on Snowflake.'
      },
      {
        type: 'p',
        value: 'I helped smava migrate from an on-premise data warehouse to cloud-native, event-driven BI built on top of Snowflake and Tableau.'
      },
      {
        type: 'p',
        value: <span>See <a href="/references/Mattias-Forsberg">Mattias Forsberg's</a> (CTO at Qliro)
          and <a href="/references/Eric-Heymann">Eric Heymann's</a> (smava SVP Technology) perspectives.</span>
      },
      {
        type: 'img',
        value: smavaBoard,
        title: <span>Read <a href="/products/smava">smava story</a>.</span>
      },
      {
        type: 'img',
        value: qliroStart1,
        title: <span>Read <a href="/products/qliro">Qliro story</a></span>
      },
    ],
    action: 'read more',
    href: '/products/Snowflake'
  },
  {
    type: 'preview',
    image: {
      src: qliroLogo,
      circle: qliroLogoCircle,
      alt: 'Qliro – build a new Data Team'
    },
    years: '2021 – 2022',
    title: 'Qliro – transform data warehousing',
    text: 'In 2022, I helped Qliro transform both, the Data Team and their data warehousing technology',
    content: [
      {
        type: 'p',
        value: `Mid-2021,f I was asked by Qliro AB to act as an interim manager in the Data Team. The team itself was in the middle of a huge infrastructure shift (data center migration combined with a major Spark upgrade). This process forced the team to focus purely on maintenance and migration. The lack of new development resulted in a massive brain drain that threatened operational continuity.`
      },
      {
        type: 'p',
        value: `During the first six months, we completed DC and Spark migration and stabilized the environment. More importantly, five new engineers were onboarded, which created a foundation for a new team.`
      },
      {
        type: 'p',
        value: 'In the second phase, we onboarded the very first users to Snowflake. The Product and Marketing Team was finally able to freely combine web traffic and core-system in a unified environment. Technically, it was a massive data-ingestion process followed by migration of existing logic written in TSQL, Python, and Spark/Scala. Our destination architecture was built on GIT-managed DBT, running on top of Snowflake and orchestrated with Airflow.'
      },
      {
        type: 'p',
        value: 'In the meantime, I handed over a working and motivated team to a new Head of Data and stepped aside. My task was now to safely lead the company to the point where it becomes completely independent of the legacy data processing.'
      },
      {
        type: 'p',
        value: <span>See <a href="/references/Mattias-Forsberg">Mattias Forsberg's</a> (CTO at Qliro),
          and <a href="/references/Oskar-Tarring-Weijden">Oskar Tarring Weijden</a> (Head of Product and
          Customer Analytics at Qliro) perspectives.</span>
      },
      {
        type: 'img',
        value: qliroStart1,
        title: ``
      },
      {
        type: 'img',
        value: qliroStart2,
        title: ''
      },
      {
        type: 'img',
        value: qliroFun2,
        title: ``
      },
      {
        type: 'img',
        value: qliroFinish,
        title: ``
      }
    ],
    action: 'read more',
    href: '/products/qliro'
  },
  {
    type: 'preview',
    image: {
      src: smavaLogo,
      circle: smavaLogoCircle,
      alt: 'smava – moving reporting to the future'
    },
    years: '2019 – 2020',
    title: 'smava – moving reporting to the future',
    text: 'I helped smava migrate from an on-premise data warehouse to cloud-native, event-driven BI built on top of Snowflake and Tableau.',
    content: [
      {
        type: 'p',
        value: `In autumn 2019 I was asked by smava to push their reporting and business intelligence into the XXI century. Legacy in-house infrastructure based on PostgreSQL and Pentaho Data Integration with thousands of custom SQL scripts was seriously unstable and far behind business needs.`
      },
      {
        type: 'p',
        value: `Special tasks require special forces. I created a cross-functional, independent team of data engineers, dev-ops, marketing, and analytics. We decided to split our job into three main phases.`
      },
      {
        type: 'p',
        value: <span><strong>Stage one</strong> was focused on stability. Our goal was to allow the business to have the data they need every single morning. We defined success metrics and gradually started implementing monitoring, failover backups, and disaster recovery plans. This phase gave us stakeholders credibility.</span>
      },
      {
        type: 'p',
        value: <span>The <strong>second stage</strong> was all about performance. Not only some of the reports were delivered in the afternoon but it was getting slower every single week. Our target goal was to deliver all top-priority reports by 6 am. And we did it. This phase bought us time to start working on a desired solution.</span>
      },
      {
        type: 'p',
        value: <span>In the <strong>third stage</strong>, we were looking for a future reporting solution for smava. After a careful review of the existing options on the market, we decided to run two proof of concepts for Snowflake and AWS Redshift. This led us to a fully informed decision—building the future of reporting on top of Snowflake.</span>
      },
      {
        type: 'p',
        value: <span> See <a href="/references/Eric-Heymann">Eric Heymann's (smava SVP Technology) perspective</a>.</span>
      },
      {
        type: 'img',
        value: smavaBoard,
        title: `Archeology—let's first understand what we have. Working on stability first.`
      },
      {
        type: 'img',
        value: smavaTT1,
        title: 'The second phase—chasing performance bottlenecks.'
      },
      {
        type: 'img',
        value: smavaTT2,
        title: `Third phase—dev-ops, data engineer, and analytics working arm-to-arm.`
      },
      {
        type: 'img',
        value: smavaContract,
        title: `Let's agree on how we work together.`
      },
      {
        type: 'img',
        value: smavaGoal,
        title: `The clear goal that we all agree on is a king.`
      },
      {
        type: 'img',
        value: smavaRoom,
        title: `Dedicated space allows the team to work focused is critical.`
      }
    ],
    action: 'read more',
    href: '/products/smava'
  },
  {
    type: 'preview',
    image: {
      src: Folx,
      alt: 'Folx – the first in-app mobile network operator'
    },
    years: '2016 – 2017',
    title: 'Folx – the first in-app mobile network operator',
    text: 'I helped Folx to introduce the first mobile network operator solely in an app. As part of the project, native mobile applications for iOS and Android were created featuring full on-boarding, identity confirmation, payments, self-care and customer support. Another Uber-Driver-like application was developed to allow SIM card delivery in up to 15 minutes since the request.',
    content: [
      {
        type: 'p',
        value: `At the beginning of 2016 I was asked by Play.pl (one of the largest Polish GSM operators) to help in creation of a new product: Folx. I formed a team of engineers, testers and designers to create a set of three mobile applications. From the development perspective it was a mixture of Spotify, Uber and Uber Driver. My team was responsible for native iOS & Android development, manual and automated testing, UI consultancy & design and backend mockup.`
      },
      {
        type: 'p',
        value: `I have trained a new project manager and then successfully handed-over this project to him. One of the greatest skills of a PM is an ability to scale and train new managers. I was more than happy to see my past project being perfectly managed by my trainee.`
      },
      {
        type: 'img',
        value: FolxDemo,
        title: 'Getting ready to start sprint review.'
      },
      {
        type: 'img',
        value: FolxRetro,
        title: 'One of the bi-weekly retrospectives.'
      },
      {
        type: 'img',
        value: FolxStandup,
        title: `Team's morning standup meeting.`
      },
      {
        type: 'img',
        value: FolxTests,
        title: 'Team watching first tests in an UX lab (with one way mirror).'
      },
      {
        type: 'img',
        value: FolxGif,
        title: `Folx app main screen.`,
        size: 'small'
      }

    ],
    action: 'read more',
    href: '/products/Folx'
  },
  {
    type: 'description',
    image: {
      src: Albert,
      alt: 'Albert – custom, PCI-certified operating system'
    },
    years: '2015 – 2017',
    title: 'Albert – custom, PCI-certified operating system',
    text: 'I helped Aevi (a spin-off of the Wincor-Nixdorf company) in the delivery of a custom, hardened, PCI-certified version of the Android Lollipop operating system called Albert OS. Albert itself is the world’s first PCI-certified touchscreen payment terminal.',
    content: [
      {
        type: 'p',
        value: 'In 2015 I was asked to manage an international team of about 25 engineers, testers and business analytics spanned across several countries in Europe including Czech, Poland, Germany, France, UK and Italy. Our goal was to create an Android based operating system to replace the existing (older) version installed on over 100K devices in Australia.'
      },
      {
        type: 'p',
        value: 'To be able to work with such big and geographically distributed team, under strict security constraints and to introduce agility within a conservative milestone-driven environment was a great adventure.'
      },
      {
        type: 'p',
        value: 'This project was also gigantic from the technical perspective. With almost 500 GIT repositories the initial project build time exceeded two hours. Everything that was trivial in a "regular" project was big in Albert.'
      },
      {
        type: 'p',
        value: <span>Read more about Albert at <a href="https://www.polidea.com/our-work/A_Step_Further_in_Payment_Solution_Revolution/" target="_blank" rel="noopener noreferrer">Polidea's website</a>.</span>
      },
      {
        type: 'img',
        value: AlbertDemo,
        title: 'One of the sprint review session in front of the Product Owner.'
      },
      {
        type: 'img',
        value: AlbertWorkshops,
        title: `One of the workshops in Aevi's headquarter`
      },
      {
        type: 'img',
        value: AlbertSlack,
        title: `Project's size in its peak.`,
        size: 'sm'
      },
      {
        type: 'img',
        value: AlbertMeetup,
        title: 'One of the workshops in Warsaw at Polidea office.'
      },
      {
        type: 'img',
        value: AlbertTalk,
        title: 'I gave couple of public speeches about organizing projects workshops for teams exceeding 20 people. Here at Agile Warsaw in 2017.'
      }
    ],
    action: 'read more',
    href: '/products/Albert'
  },
  {
    type: 'description',
    image: {
      src: IkoProject,
      alt: 'IKO – mobile payment and banking app'
    },
    years: '2013 – 2014',
    title: 'IKO – mobile payment and banking app',
    text: 'I was responsible for the delivery of the second version of IKO. IKO is a mobile payment and mobile banking solution of the largest bank in Poland PKO BP, with over 2m active users. I formed and led a 15+ team of Hewlett-Packard and Polidea engineers, designers, and testers.',
    content: [
      {
        type: 'p',
        value: 'I joined the project in 2012 helping HP in the delivery of the first version of IKO. This version was created by a Swedish company using cross-platform technologies. As you may guess the bank was not satisfied with the performance of the application. Then I was asked to rewrite IKO from scratch using native technologies in less than 7 months.'
      },
      {
        type: 'p',
        value: 'The scope of IKO2 was huge. Native mobile applications for iOS, Android, Windows Phone, J2ME and Blackberry plus backend server. Integration, automated and manual testing, continuous integration, security and partly UX.'
      },
      {
        type: 'p',
        value: 'I formed a new team from Hewlett-Packard and Polidea employees. The project was kicked-off in September 2013 and by March 2014 we managed to deliver IKO2 to our client. This work was done three times faster than IKO1. Note that there were no code reuse (different technologies) and no knowledge share (different teams).'
      },
      {
        type: 'p',
        value: <span>IKO was ranked <a href="https://retail-banker.nridigital.com/retail_banker_apr18/rbi_50_the_best_of_the_best_incumbent_banks_mobile_apps_ranked" target="_blank" rel="noopener noreferrer">the best mobile banking application</a> in the world by Retail Banker International.</span>
      },
      {
        type: 'p',
        value: 'IKO2 project was continued as we were adding new features to the app. Later on the same team delivered IKO-Junior application (hope it\'s self descriptive).'
      },

      {
        type: 'img',
        value: IkoBoard,
        title: 'I was using a combination of Scrum and Kanban with short iterations. This is our Kanban board presenting a Sprint Plan.'
      },
      {
        type: 'img',
        value: IkoJunior,
        title: 'Before a real sprint review with our client we were performing a pre-demo to ensure that everything is working as planned. Here PKO-Junior application is being pre-demonstrated.'
      },
      {
        type: 'img',
        value: IkoOrganic,
        title: `During 18 months of development the team was constantly evolving to adapt to new expectations. We were moving our desks every second month to ensure we are sitting in a perfect setup.`
      },
      {
        type: 'img',
        value: IkoWork,
        title: '"Bug squeezing party"'
      }
    ],
    action: 'read more',
    href: '/products/IKO'
  },
  /*
  {
    type: 'preview',
    image: {
      src: 'assets/images/fom.png',
      alt: 'FOM – online brand protection'
    },
    years: '2015',
    title: 'FOM — online brand protection',
    text: 'FOM is my startup focused on online brand protection. Automated services allow FOM to scan thousands of pages to find counterfeits, unauthorized use of brand name or logo and other examples of unfair competition.',
    content: [
      {
        type: 'p',
        value: <span>More at <a href="https://fom.org.pl" target="_blank" rel="noopener noreferrer">Forum Ochrony Marki</a> [PL]</span>
      },
      {
        type: 'img',
        value: 'assets/images/panel-fom.png',
        title: `FOM client panel showing automatically scrapped and validated sales offers with counterfeits.`
      }
    ],
    action: 'read more',
    href: '/products/FOM'
  },*/
  {
    type: 'preview',
    image: {
      src: Stojaki,
      alt: 'Stojaki – café'
    },
    years: '2014 – 2018',
    title: 'Stojaki — café in my hometown',
    text: 'Stojaki (a “bike rack” in Polish, but also “to stand” — it’s a word play) was a local café, pub and meeting point which operated in my hometown Szczecin from 2014 until 2018.',
    content: [
      {
        type: 'p',
        value: 'Without As there was no decent breakfast and coffee spot in my hometown I decided to open a café. The challenge was that I was still managing projects in Warsaw (500km away). And so this became/Therefore  this was one of the greatest lesson on delegating duties.'
      },
      {
        type: 'p',
        value: <span>Stojaki quickly became a hot and vibrant place to meet. Funny fact: Stojaki was promoted by the Polish Ministry of Foreign Affairs in their "About Poland" travelers guide. Take a look at <a href="https://issuu.com/msz.gov.pl/docs/about_polska_en_issuu/155" target="_blank" rel="noopener noreferrer">"About Poland"</a>.</span>
      },
      {
        type: 'p',
        value: 'In 2018, after months of not visiting my hometown I decided to sell my place to someone who spends more time in Szczecin. The place is still operating under a new brand.'
      },
      {
        type: 'p',
        value: <span>More at <a href="http://stojaki.szczecin.pl" target="_blank" rel="noopener noreferrer">Stojaki's website</a> and <a href="https://fb.com/stojaki.szczecin" target="_blank" rel="noopener noreferrer">Stojaki's FB fanpage</a>.</span>
      },
      {
        type: 'img',
        value: StojakiBirthday,
        title: 'Third birthday of Stojaki.'
      },
      {
        type: 'img',
        value: StojakiInside,
        title: 'Stojaki as a checkpoint at bike messengers Alley-Cat event.'
      }
    ],
    action: 'read more',
    href: '/products/Stojaki'
  },
  {
    type: 'preview',
    image: {
      src: Blik,
      alt: 'BLIK – White-label mobile payment app'
    },
    years: '2014',
    title: 'BLIK — White-label mobile payment app',
    text: 'In 2014 I created a White-label application for Polish Payment Standard (PSP). PSP is a company formed by six leading Polish banks. PSP provides their customers mobile payment service BLIK.',
    content: [
      {
        type: 'p',
        value: <span>After a successful implementation of the mobile payment application IKO (described <a href="/products/IKO">here</a>) the largest Polish bank PKO decided to introduce a new payment standard. Together with five banks (which together owned more than a half of market share) Polish Payment Standard was established. I was asked to create a reference white-label application for other banks to reuse. This new service got the name: BLIK.</span>
      },
      {
        type: 'p',
        value: 'BLIK allows users to make online and in-store payments, make deposits and withdrawals at ATMs and send instant P2P transfers. With 33m transactions in the first half of 2018 BLIK is becoming the fastest and the most popular form of online payments and instant P2P transfers.'
      },
      {
        type: 'p',
        value: 'This application was significantly smaller than IKO as it was mainly focused on one feature—mobile payments using six-digit BLIK code. On the other hand, this app was not only developed but also exclusively designed by my team.'
      },
      {
        type: 'p',
        value: 'My team was responsible for the delivery of a reference, well documented mobile applications for iOS, Android and Windows Phone. Additionally, a proxy backend server to securely serve a content to these apps was delivered.'
      },
      {
        type: 'p',
        value: <span>More at <a href="http://www.blikmobile.pl" target="_blank" rel="noopener noreferrer">Blik Mobile</a> [PL]</span>
      },
      {
        type: 'img',
        value: BlikDemo,
        title: `One of the sprint review meetings. Left to right: Android, WP and iOS clients being presented.`
      }
    ],
    action: 'read more',
    href: '/products/BLIK'
  },
  {
    type: 'preview',
    image: {
      src: Evently,
      alt: 'Evently – events aggregator'
    },
    years: '2011 – 2012',
    title: 'Evently — events aggregator',
    text: 'It’s my dead startup from 2011. Evently’s goal was to present interesting events happening around you. No past events, no concerts happening in three months in a different city, no useless search and category browser.',
    content: [
      {
        type: 'p',
        value: `I managed to create an MVP and continued feeding it good quality content for three months. I talked to couple investors but I failed trying to convince them to my business model.`
      },
      {
        type: 'p',
        value: `I was responsible for... everything but I neither coded nor designed it. I relied on freelancers and students and have invested my own money.`
      },
      {
        type: 'p',
        value: `Lesson learned: a real MVP can be much slimmer than you think.`
      },
      {
        type: 'img',
        value: EventlyPage,
        title: `Evently's main page in it's beauty.`
      }
    ],
    action: 'read more',
    href: '/products/Evently'
  },
  {
    type: 'preview',
    image: {
      src: EngryFile,
      alt: 'AngryFile – file-sharing tool'
    },
    years: '2011',
    title: 'AngryFile — file-sharing tool',
    text: 'In 2011 I worked as a consultant for Red-Sky and Softline. These companies joined forces to prepare a new product: AngryFile. I was responsible for this joint-venture.',
    content: [
      {
        type: 'p',
        value: `My role was to create the product starting from its concept, product's identity and design, development, marketing and sales. Within six months my team managed to release a fully featured product to production. This included Windows/Mac/Linux desktop clients, storage-backend, website, promo-video and other marketing materials.`
      },
      {
        type: 'p',
        value: <span>The logo prepared for me by <a href="https://redkroft.com/" target="_blank" rel="noopener noreferrer">Redkroft</a> received thousands of apprecietes at <a href='https://www.behance.net/gallery/1617781/AngryFile' target="_blank" rel="noopener noreferrer">Behance</a> and was presented at <a href='http://www.symboltologo.com/' target="_blank" rel="noopener noreferrer">The Second Polish Graphics Marks</a> exhibition in 2017.</span>
      },
      {
        type: 'p',
        value: 'I am still very proud of the design and development work but... as it usually happens with startups I failed in marketing and sales. The number of active subscriptions was too small to continue the investment. If only I had read "The Lean Startup" before!'
      },
      {
        type: 'img',
        value: EngryFileLogo,
        title: 'The Second Polish Graphics Marks exhibition'
      },
      {
        type: 'img',
        value: '/assets/images/angry-simple.png',
        title: 'Angryfile running on OSX',
        size: 'sm'
      },
      {
        type: 'movie',
        value: 'https://vimeo.com/25173356'
      },
      {
        type: 'img',
        value: EngryFileFanpage,
        title: `Angryfile's Facebook poster`,
        size: 'sm'
      }
    ],
    action: 'read more',
    href: '/products/AngryFile'
  },
  {
    type: 'preview',
    image: {
      src: Microbus,
      alt: 'Microbus – mobile timetable'
    },
    years: '2005 – 2008',
    title: 'Microbus — mobile timetable',
    text: 'In mid 2005 (before the launch of YouTube!) I created the first in Poland and one of the world’s first mobile timetable of public transport called Microbus. The application had a total of approximately 100 thousand downloads in my hometown.',
    content: [
      {
        type: 'p',
        value: 'This happened in times when WIFI was not supported and the usage of GPRS was rare. Maximum application size (including timetable data) varied between 16kb and 64kb, whereas the whole timetable in plain HTML (no graphics) was 1MB size. From a technical point of view it was a great algorithmic and performance experience.'
      },
      {
        type: 'p',
        value: 'I believe this is the only piece of code I have ever written that was used so broadly "on production".'
      },
      {
        type: 'p',
        value: <span>a to my <a href="http://www.ipipan.waw.pl/~subieta/prace%20magisterskie/Zestaw%20narzedzi%20programistycznych%20do%20generowania%20mobilnych%20aplikacji%20-%20Nieradka.pdf" target="_blank" rel="noopener noreferrer">master's thesis</a> [PL] defended at Polish-Japanese Academy of Information Technology Prof. Dr. Habil. Eng. <a href="http://pl.wikipedia.org/wiki/Kazimierz_Subieta" target="_blank" rel="noopener noreferrer">Kazimierz Subieta </a>.</span>
      },
      {
        type: 'img',
        value: MicrobusNokia,
        title: 'Microbus running on Nokia 6310.'
      },
      {
        type: 'img',
        value: MicrobusSymbian,
        title: 'Microbus running on a Symbian enabled device.'
      }
    ],
    action: 'read more',
    href: '/products/Microbus'
  }
];
