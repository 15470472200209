import React from 'react';
import LinkComponent from './link.jsx';
import { Link } from 'react-router-dom';
import './depiction.scss';
import classNames from 'classnames';

export default ({ modifier, product }) => {
  const depictionClasse = classNames('depiction', `depiction--${modifier}`);
  return (
    <div className={depictionClasse}>
      <div className='depiction__row'>
        <h3 className='depiction__title'>{product.title}</h3>

        <p className='depiction__text'>{product.text}</p>

        <LinkComponent href={product.href} text={'read more'} className='depiction__action' />
      </div>

      <div className='depiction__row'>
        <Link to={product.href} className='depiction__invisibleLink' alt={product.image.alt}>
          <img src={product.image.src} alt={product.image.alt} className='depiction__project' />
        </Link>
      </div>
    </div>
  );
};
