import React from 'react';
import './timeline.scss';
import Heading from './heading.jsx';
import { education } from '../translation';
import { LocationIcon } from './icons.jsx';
import EducationIcon from '../assets/images/education-icon.svg';

education.list

const Education = () => (
  <div className='timeline'>
    <ul className='timeline__list'>
      <li className='timeline__item'>
        <img src={EducationIcon} alt='certificate icon' className='timeline__icon' />
        <Heading text={education.heading} />
      </li>

      {education.list.map((item, i) => (
        <li key={i} className='timeline__item'>
          <span className='timeline__time'>{item.date}</span>
          <h4 className='timeline__school'>{item.school}</h4>
          <p className='timeline__city'>
            <LocationIcon />
            <span>{item.city}</span>
          </p>
          <p className='timeline__description'>{item.description}</p>
        </li>
      ))}
    </ul>
  </div>
);

export default Education;
