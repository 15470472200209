import React from 'react';
import './productPreview.scss';
import classNames from 'classnames';

import Back from './back.jsx';
import ScrollToTop from "../helpers/scrollToTop";

const Preview = ({ image, title, text, content, years }) => {
  const generateImage = (item, index) => (
    <figure key={index} className={classNames('productPreview__imageWrapper', { ['productPreview__imageWrapper--sm']: item.size} )}>
      <img src={item.value} alt='avatar preview' className='productPreview__image' />
      <figcaption className='productPreview__caption'>{item.title}</figcaption>
    </figure>
  );

  const generateText = (item, index) => (
    <p key={index} className='productPreview__text'>{item.value}</p>
  );

  const generateMovie = (item, index) => (
    <p key={index} className='productPreview__movie' ref={c => vimeo = c}>{item.movie}</p>
  )

  return (
    <div className='productPreview'>
      <header className='productPreview__header'>
        <div className='productPreview__headerContainer'>
          <Back />

          <h2 className='productPreview__hint'>{years}</h2>
          <h1 className='productPreview__title'>{title}</h1>
        </div>
      </header>

      <section className='productPreview__tile'>
        <img className='productPreview__preview' src={image.circle ? image.circle : image.src} alt={image.alt} />
      </section>

      <section className='productPreview__content'>
        <p className='productPreview__subtitle'>{text}</p>

        {content.map((item, i) => {
          if (item.type === 'p') return generateText(item, i);
          else if (item.type === 'img') return generateImage(item, i);
          else if (item.type === 'movie') return generateMovie(item, i);
          else return null
        })}
      </section>
      <ScrollToTop />
    </div>
  )
}

export default Preview;