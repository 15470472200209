import './back.scss';
import { Link, useNavigate } from 'react-router-dom';
import React from 'react';

const Back = () => {
  const navigate = useNavigate()

  function historyBackWFallback() {
    if (window.history.length > 2)
      return navigate(-1)
    navigate('/')
  }

  return <Link onClick={historyBackWFallback} className='back' />
};

export default Back;