import React from 'react';
import Navbar from '../components/nav.jsx';
import Hero from '../components/hero.jsx';
import Heading from '../components/heading.jsx';
import Section from '../components/section.jsx';
import Description from '../components/description.jsx';
import Depiction from '../components/depiction.jsx';
import Timeline from '../components/timeline.jsx';
import FlexList from '../components/flex-list.jsx';
import Carousel from '../components/carousel.jsx';
import Tile from '../components/tile.jsx';
import Tabs from '../components/tabs.jsx';
import Footer from '../components/footer.jsx';
import Logos from '../components/logos.jsx';

import { references, products } from '../translation';
const productPreviews = products.filter(product => product.type === 'description');

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />

      <main>
        <Section modifier="spaced" id="about">
          <Description />
        </Section>

        <Section modifier="shapedTop" type="dark" id="products">
          {productPreviews.map((product, index) => (
            <Depiction key={index} modifier={index % 2 === 0 ? "reversed" : "default"} product={product} />)
          )}
        </Section>

        <FlexList />

        <Section modifier="shaped" type="color" id="references">
          <Heading text="References" color="white" type="centered" />

          <Carousel>
            {references.map((reference, i) => <Tile key={i} content={reference} />)}
          </Carousel>
        </Section>

        <Section modifier="shapedBottom" id="education">
          <Timeline />
        </Section>

        <Section type="dark" modifier="spaced" id="jobs">
          <Heading text="Jobs" />

          <Tabs />
        </Section>

        <Section id="logos">
          <Logos />
        </Section>
      </main>

      <Footer />
    </div>
  );
}

export default Home;
