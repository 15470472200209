import { Link } from 'react-router-dom';
import './nav.scss';
import React from 'react';

export default () => (
  <nav className='navbar'>
    <div className='navbar__container'>
      <Link to="/" className='navbar__link'>
        szymon&#x7B;nieradka&#x7D;
      </Link>
    </div>
  </nav>
);
