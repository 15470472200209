import React from 'react';
import { useLoaderData } from "react-router-dom";

import ReferencesPreview from '../components/referencesPreview';


const References = () => {
  const content = useLoaderData()

  return (
    <ReferencesPreview {...content} />
  );
};

export default References;
