import './footer.scss';
import { footer } from '../translation/index';
import React from 'react';

export default () => {
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <ul className='footer__list'>
          <li className='footer__item'>
            <h4 className='footer__heading'>{footer.location.header}</h4>
            <p className='footer__text'>{footer.location.text}</p>
          </li>

          <li className='footer__item'>
            <h4 className='footer__heading'>{footer.contact.header}</h4>

            <a className='footer__link' href={footer.contact.phone.href} target="_blank" rel="noopener noreferrer">
              {footer.contact.phone.text}
            </a>

            <a className='footer__link' href={footer.contact.email.href} target="_blank" rel="noopener noreferrer">
              {footer.contact.email.text}
            </a>
          </li>

          <li className='footer__item'>
            <h4 className='footer__heading'>{footer.links.header}</h4>

            <div className='footer__icons'>
              <a className='footer__icon' href={footer.links.linkedin.href} target="_blank" rel="noopener noreferrer">
                <img src={footer.links.linkedin.icon} alt='LinkedIn icon' />
              </a>

              <a className='footer__icon' href={footer.links.medium.href} target="_blank" rel="noopener noreferrer">
                <img src={footer.links.medium.icon} alt='Medium icon' style={{ width: '19px' }}/>
              </a>
            </div>
          </li>
        </ul>

        <div className='footer__copyright'>
          <small>{footer.logo}</small>
          <small>{footer.copyright}</small>
        </div>
      </div>

      <img src={footer.shape} alt='footer background shape' className='footer__bgShape' />
    </footer>
  );
};
