import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";

import Home from './routes/home';
import Details from './routes/details';
import References from './routes/references';

import { products } from './translation/products';
import { references } from './translation/references';

function App() {

  const dataLoader = (entries, name) => {
    const content = entries.filter(item => item.href.endsWith(name)).pop()
    if (content)
      return content
    return redirect('/')
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/products/:name",
      element: <Details />,
      loader: ({ params }) => dataLoader(products, params.name)
    },
    {
      path: "/references/:name",
      element: <References />,
      loader: ({ params }) => dataLoader(references, params.name)
    },
    {
      path: "*",
      loader: () => redirect('/')
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  )
}

export default App;
