import './card.scss';
import { Link } from 'react-router-dom';
import React from 'react';

export default ({ content }) => {
  const { image, title, href, years } = content;

  return (
    <article className='card'>
      <Link to={href} className='card__link' alt={title} />
      <header className='card__header'>
        <figure className='card__imageWrapper'>
          <img src={image.src} alt={image.alt} className='card__image' />
        </figure>
      </header>

      <section className='card__content'>
        <h4 className='card__hint'>{years}</h4>

        <h3 className='card__title'>{title}</h3>
      </section>
      
    </article>
  );
};