import React from 'react';
import './tile.scss';
import Link from './link.jsx';
import { OfficeIcon } from './icons';
import { Link as RouteLink } from 'react-router-dom';

export default ({ content }) => {
  const { image, name, job, company, action, href, short } = content;

  return (
    <article className='tile'>
      <RouteLink to={href} className='tile__link' alt={name}/>
      <header className='tile__header'>
        <figure className='tile__imageWrapper'>
          <img src={image.src} alt={image.alt} className='tile__image' />
        </figure>

        <div className='tile__heading'>
          <h3 className='tile__title'>{name}</h3>

          <p className='tile__subtitle'>{job}</p>
          {company && (
            <p className='tile__company'>
              <OfficeIcon />
              {company}
            </p>
          )}
        </div>
      </header>

      <section className='tile__content'>
        <p className='tile__text'>{short}</p>

        <Link href={href} text={action} variant='flat' className='tile__action' />
      </section>
    </article>
  );
};

